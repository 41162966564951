import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import ReactTooltip from 'react-tooltip';

const API_PATH = "/api-mail-top-en.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("This field is required"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("This field is required"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa"),
  privacy: Yup.bool().oneOf([true], "This field is required."),
  privacy2: Yup.bool().oneOf([true], "This field is required."),
})

export default class ContactFormTop extends Component {


  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      position: "",
      region: "",
      sallary: "",
      description: "",
      mailSent: false,
      error: null,
      privacy: false,
      privacy2: false,
    }
  }

  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.fbq('track', 'Lead');
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
            position: "",
            region: "",
            sallary: "",
            description: "",
            privacy: false,
            privacy2: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="">
              <h3 className="text-2xl font-bold text-dark-gray"> See how <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">Rex</span> works!</h3>
              <div className={this.props.formState ? "grid grid-cols-1 gap-10" : "grid md:grid-cols-2 md:gap-20"}>
                <div>
                  <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="name" placeholder="First and last name"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="name"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="email" placeholder="E-mail address*"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="email"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="phone" placeholder="Phone number"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="phone"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="message" placeholder="Message" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="message"
                          component="span"
                        />
                    </div>
                    </div>
                </div>
                <div id="hidden-fields" className={this.props.formState ? "hidden" : "" }>
                <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="position" placeholder="Position"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="position"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="region" placeholder="Recruitment area"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="region"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="sallary" placeholder="Gross monthly salary in EUR:"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="sallary"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="description" placeholder="Description" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="description"
                          component="span"
                        />
                    </div>
                    </div>
                    
                </div>

                <div className="privacy-policies -mt-10">
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-4"
                        htmlFor="privacy"
                        data-tip="I hereby consent to the processing of my personal data by Antal sp. z o.o. with its registered office in Warsaw (02-566), at Puławska Street no 2, entered in the Register of Entrepreneurs under the KRS number: 0000825336, used for the processing of enquiries. <br>At the same time I declare that: <br>1. I am aware that the provision of my data is voluntary and the processing is based on my consent; <br>2.I am aware that I have the right to withdraw my consent at any time; <br>3.I am aware that my personal data will be processed until the completion of the enquiry for which I have given my consent or until the consent is revoked, with the proviso that after the completion of the enquiry or revocation of consent, the data may continue to be processed during the period of limitation of claims to which the controller is entitled and those to which the data subject is entitled against the controller.<br>4.I have made a note that the withdrawal of consent can be made by sending an email to: mail@antal.pl;"
                      >
                        Consent to the processing of personal data  <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy"
                        name="privacy"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy"
                          component="span"
                        />
                      </label>
                      <ReactTooltip arrowColor={"#fff"} html={true} className="policy-tooltip"/>
                    </div>
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-5"
                        htmlFor="privacy2"
                        data-tip="I hereby agree to receive commercial information by electronic means from Antal sp. z o. o. within the meaning of the Act of 18 July 2002 on Providing Services by Electronic Means (Journal of Laws of 2013, item 1422) as well as the use by Antal sp. z o. o. of telecommunications terminal equipment and automated calling systems within the meaning of the Telecommunications Act of 16 July 2004 (Journal of Laws of 2014, item 243). I declare that I am aware that giving consent is voluntary. I have made a note that the withdrawal of consent can be made by sending an email to: mail@antal.pl"
                      >
                        Consent to receiving commercial information <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy2"
                        name="privacy2"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy2"
                          component="span"
                        />
                      </label>
                    </div>
                    </div>
              </div>
              
              <div className="flex justify-end py-10">
              <button
                className="bg-teal text-dark-gray text-sm font-bold px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple"
                type="submit"
                disabled={isSubmitting}
              >
                Send <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-1 w-3 h-3 inline-block">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /> </svg>
              </button>
              </div>
              
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Thank you!</h3>
          <p className="text-xl mb-12">Your message has been sent.</p>
        </form>
      )
    }
  }
}
