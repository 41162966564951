import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "./contactForm"
const Download = () => {
  return (
    <section id="pobierz-ebook" className="bg-light-pink">
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 gap-24">
            <div className="z-10 relative col-span-2 flex flex-col justify-end mr-24 text-lg">
                <p className="text-purple mb-12">
                How to recruit specialists and managers?  <br/>
                Metaversum in recruitment - the coming trend or the distant future? <br/>
                Crowdstaffing, contracting, RPO - how popular are these recruitment services? 
                </p> 
                <p className="text-purple font-bold mb-10">Download the Antal report published in cooperation with ReX "Trends in the recruitment of professionals and managers" and find out the latest survey results, as well as comments from experts!</p>
            </div>
            <div className="flex justify-center items-center px-12">
                <StaticImage className="" src={'../../images/Rex-mockup-klienci.png'} />
            </div>
        </div>
        <div className="max-w-7xl mx-auto">
            <ContactForm />
        </div>
        
    </section>
  )
}

export default Download