import * as React from "react"
import '../styles/global.css'
import Header  from "../components/en/header";
import Top from "../components/en/top";
import About from "../components/en/about";
import Benefits from "../components/en/benefits";
import NoLimits from "../components/en/no-limits";
import HowWeWork from "../components/en/how-we-work";
import Clients from "../components/en/clients";
import Testimonials from "../components/en/testimonials";
import Examples from "../components/en/examples";
import Download from "../components/en/download";
import Team from "../components/en/team";
import Leaders from "../components/en/leaders";
import LeaderSupport from "../components/en/leader-support"
import Faq from "../components/en/faq";
import Footer from "../components/en/footer";
import { StaticImage } from "gatsby-plugin-image"
import PriceComparison from "../components/en/priceComparison";




const IndexPage = () => {
  return (
    <div>
      <Header />
      <div className="relative">
      <div className="x-container absolute h-full w-full object-left-top z-20">
            {/* <StaticImage className="inset-x-0" src={'../images/X-header.png'} alt="X" /> */}
        </div>
      <Top />
      <About />
      </div>
      <NoLimits />
      <HowWeWork />
      <PriceComparison />
      <Clients />
      <Testimonials />
      <Examples />
      <Download />

      <Leaders />
      {/* <LeaderSupport /> */}
      <Faq />
      <Footer />
    </div>
  )
}

export default IndexPage

export const Head = () => <title>REX | Recruitment Exchange powered by Antal</title>
