import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LogoRex from "../../images/logo-rex.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Examples = () => {
    let settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 800,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                  },
              },
              ]
    };


  return (
    <section  className="examples relative pt-52 pb-10 bg-center -mt-40" id="achievements">
        <div className="absolute bg-light-pink w-full left-0 h-1/2 bottom-0">

        </div>
        <div className="max-w-7xl mx-auto mt-40 md:mt-20 ">
            <h2 className="text-white font-bold text-3xl mb-12" >Examples <br/> of projects </h2>
            <p className="text-white mb-0 "  >ReX Recruiters can carry out even the most difficult of recruitment processes.<br/> 
            Our community comprises experts specialising in all sectors. We can find an employee for you,<br/> 
            whether you need 1, 10 or 100 people for your team.</p>
            <Slider {...settings} className="relative -mx-10" >

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-k.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Junior Accountant</h3>
                        <p className="text-xs mb-6">Responsible for monthly financial statements, settlements with the Social Insurance Institution (ZUS), Central Statistical Office (GUS) and Tax Office, payroll, settlement of employees, archiving of documentation, work with programs: Płatnik and Progmann – Kadry, Przelewy.</p>
                        <p className="text-xs font-bold">We usually recruit candidates for this position in Poznań, Wrocław and Warsaw for approx. PLN 7,000.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika – Junior Accountant – Recruitment price – PLN 7,000</h3>
                        <p className="text-sm">Accounting and HR matters of companies applying full and simplified accounting.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/anna.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Anna, CS with Slovenian</h3>
                        <p className="text-xs mb-6">Responsible for comprehensive service of the online store’s orders and complaints. Phone and e-mail service for Slovenian clients, advice on the product offered.</p>
                        <p className="text-xs font-bold">We usually recruit candidates for this position in Warsaw for approx. PLN 8,500.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Anna, CS with Slovenian – Recruitment price – PLN 8,500</h3>
                        <p className="text-sm">Contact by e-mail and phone with clients that have bought the client’s product.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/urszula.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">People Advisor </h3>
                        <p className="text-xs mb-6">Also called a HR specialist. Responsible for the entire cycle of hiring an employee and advice on the labour law.</p>
                        <p className="text-xs font-bold">We usually recruit candidates for this position in Warsaw for approx. PLN 17,000.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Urszula – People Advisor – Recruitment price: PLN 17,000.</h3>
                        <p className="text-sm">Support for employees in administrative and HR matters.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/adam.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Commercial Agent</h3>
                        <p className="text-xs mb-6">Responsible for the target implementation, taking care of existing clients and often acquiring new clients.</p>
                        <p className="text-xs font-bold">We recruit candidates for this position in all parts of Poland for approx. PLN 10,000.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Adam – Commercial Agent – Recruitment price: PLN 10,000</h3>
                        <p className="text-sm">Sale of the company’s services and representing it in contact with clients.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/ewa.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">CS with German</h3>
                        <p className="text-xs mb-6">Responsible for entering data from orders to SAP, resolving the clients’ current problems, informing clients about the status of their order.</p>
                        <p className="text-xs font-bold">We recruit candidates for this position in all parts of Poland for approx. PLN 8,000.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Ewa, CS with German – Recruitment price – PLN 8,000</h3>
                        <p className="text-sm">Contact by phone and e-mail with business clients regarding orders.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/tomek.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Customer Quality Engineer</h3>
                        <p className="text-xs mb-6">Responsible for supervising the fulfilment of the client’s requirements in the organisation. Management of the client’s complaints (8D reports + monitoring of actions). Preparation of quality analyses and reports. Preparation of PPAP for changes in serial production.</p>
                        <p className="text-xs font-bold">We recruit candidates for this position in all parts of Poland for approx. PLN 12,000.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Tomek, Customer Quality Engineer – Recruitment price – PLN 12,000</h3>
                        <p className="text-sm">First line of defence against the client’s complaints.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-c.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">C# Developer</h3>
                        <p className="text-xs mb-6">Development of software for updating data on their own.
                            Introduction of software updates according to the business demand.
                            Contact with business.</p>
                        <p className="text-xs font-bold">We recruit candidates for this position in all parts of Poland for approx. PLN 12,000.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika – C# Developer – Recruitment price – PLN 25,000</h3>
                        <p className="text-sm">Development of a calculation programme for mechanical and hydraulic loads, taking into account the drill hole.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

                
            </Slider>   
           
        </div>
    </section>    
  )
}

export default Examples