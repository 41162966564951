import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
// import Map from "../../images/mapa-gradient.svg"
import CountUp from 'react-countup';


const NoLimits = () => {

  return (
    <section id="rekrutacja-z-rex" className="no-limits relative overflow-visible object-none py-24 bg-neutral-200">
        <StaticImage src={'../../images/no-limits-bg.png'} className="absolute inset-y-0 z-0" />
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-14 pt-10">
            <div className="z-10 relative ">
            <StaticImage src={'../../images/mapa-gradient.svg'} className="" />
            </div>
            <div className="flex flex-col justify-center items-start z-10">
            <h2 className="text-4xl font-bold mb-12">Hire without <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">limitations!</span></h2>
                <p className="text-dark-gray font-light mb-14">Our ReX Recruiters can find employees for you anywhere in Poland, the Czech Republic, Slovakia and Hungary!</p>
                <div className="grid grid-cols-2 gap-10 mb-14">
                    <div className="text-dark-gray">
                        <div className="text-5xl font-bold">
                            +<CountUp enableScrollSpy end={640} />
                        </div>
                        <p className="font-light">completed recruitment processes per year</p>
                    </div>
                    <div className="text-dark-gray">
                        <div className="text-5xl font-bold">
                            <CountUp enableScrollSpy end={83} />%
                        </div>
                        <p className="font-light">effectiveness of the recruitment process</p>
                    </div>
                    <div className="text-dark-gray">
                        <div className="text-5xl font-bold">
                            +<CountUp enableScrollSpy end={120} />
                        </div>
                        <p className="font-light">professional and experienced recruiters</p>
                    </div>
                    <div className="text-dark-gray">
                        <div className="text-5xl font-bold">
                            <CountUp enableScrollSpy end={14} /> days
                        </div>
                        <p className="font-light">the average time for project execution</p>
                    </div>
                </div>
                <a href="#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">Start&nbsp;recruiting</a>
            </div>
        </div>
        
    </section>
  )
}

export default NoLimits