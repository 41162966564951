import * as React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqItems = [
    {
        question: "Who are the ReX Recruiters?",
        answer: 'They are professional, independent recruiters – freelancers or recruitment companies. To become one of our  partners, ReX Recruiters undergo a process of selection, verification and training. After signing an agreement and passing exams in the scope of processing the candidates and the GDPR, ReX Recruiters support the recruitment processes on the ReX crowdstaffing platform.',
    },
    {
        question: "Who is the ReX Partner?",
        answer: 'The ReX Partner and the ReX Recruiter are often equivalent terms; however, in agreements and in the system we distinguish them as a person or a company (ReX Partner) that is a party to the agreement, and as a recruiter (ReX Recruiter) that actually carries out the recruitment process.',
    },
    {
        question: "Who is the consultant?",
        answer: 'The consultant is the Antal employee responsible for the processing of the candidate according to the agreement signed with the client.',
    },
    {
        question: "Who is the ReX Leader?",
        answer: 'The REX Leader is someone who manages projects from the area of a given specialisation. They are responsible on the part of the client for communication and selection of ReX Recruiters appropriate for carrying out a given project. For the platform they support ReX Recruiters and assign them accurately to projects compliant with the profile of their experience.',
    },
    {
        question: "Who is the Key Account Manager?",
        answer: 'The Key Account Manager (KAM) is an expert in the field of recruitment in a given area, someone designated to serve the client and the candidate within the order, who for an additional fee provides services in consultancy, advising, verification of notifications and coordination for a given client.',
    },
    {
        question: "Crowdstaffing – How does it work?",
        answer: 'The task of the crowd is to respond to the needs of an individual person or a company. Since several highly qualified people look for candidates with a geographical dispersion using different tools and databases at the same time, they can quickly and efficiently carry out the recruitment process.',
    },
    {
        question: "How is the order submission process performed?",
        answer: 'When we receive the description of a position, we can present a quote. After it is accepted by the client, the order document we complete is sent through the portal for acceptance by both parties using electronic signatures.',
    },
    {
        question: "Do I have to register on the platform?",
        answer: 'No. Requests for proposal, descriptions of a position and any other data may be submitted by means of a form or to the following e-mail address: karolina@recruitmentexchange.pl',
    },
    {
        question: "What will I receive?",
        answer: 'The client receives profiles of selected candidates, including contact details, financial requirements, notice period, preferred form of cooperation of a given candidate and their location, as well as the recruiter’s descriptive recommendation together with a CV. At this stage, recommended candidates are interested in discussing a given offer, related to the position.',
    },
    {
        question: "How much does it cost? When is the payment made?",
        answer: 'The amount for success remains unchanged, regardless of the candidate’s remuneration. The estimated amount may be checked on the website HERE, while it is verified by us in the context of the current market situation, the candidates’ potential and adequacy of the requirements to the employment potential. We issue the invoice on the date of commencement of work by the candidate with a 14-day payment term.',
    },
    
];

const Faq = () => {
  return (
      <div className="overflow-hidden">
    <section id="faq" className="faq pt-0 pb-24 bg-gradient-to-br from-purple to-teal">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl text-center text-white font-bold mb-20">Frequently asked questions</h2>
        <Accordion allowZeroExpanded="true">
            {FaqItems.map((faqItem) => (
        <AccordionItem className="mb-10">
          <AccordionItemHeading className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
            <AccordionItemButton className="faq-button relative">
              {faqItem.question}
                <div className="arrow border-b-purple border-l-purple w-3 h-3 absolute right-0 top-0 border-[0.35rem] border-r-transparent border-t-transparent -rotate-45 transition-all"></div>
            </AccordionItemButton> 
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white px-8 py-8  text-dark-gray text-sm">
            {faqItem.answer}
          </AccordionItemPanel>
        </AccordionItem>
            ))}
            <div className="mb-10">
                <div className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
                    <div className="relative flex flex-col md:flex-row justify-between items-center">
                        <h3 className="text-2xl basis-1 mr-4">Any&nbsp;questions?</h3>
                        <div className="bg-gradient-to-r from-purple to-teal basis-full h-[2px] my-4"></div>
                        <a href="mailto:robert.nawrot@recruitmentexchange.pl" className="ml-4 basis-1 bg-teal text-dark-gray text-sm font-bold  px-24 pt-3  py-2 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
                            Write&nbsp;to&nbsp;us
                        </a>
                    </div>
                </div>
            </div>

        
      </Accordion>
      </div>
    </section>
      </div>
  )}

  export default Faq