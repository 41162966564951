import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Efektywna from '../../images/about-icons/efektywna.svg'
import Tansza from '../../images/about-icons/tansza.svg'
// import Szybsza from '../../images/about-icons/szybsza.svg'
import Nieograniczona from '../../images/about-icons/nieograniczona.svg'


const About = () => {
  return (
    <section id="about-rex" className="pb-40">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 pt-20 ">
            <div className="z-20 relative ">
                <h2 className="text-4xl font-bold pb-12">About <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span></h2>
                <p className="text-gray-900 font-light mb-14">
                Rex is an innovative crowdstaffing platform that connects clients, recruiters and candidates. ReX Recruiters can carry out even the most non-standard of recruitment processes. We respond to the clients’ needs and prove that recruitment has never been so simple!
                </p>
                
                <a href="#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline-block text-center hover:bg-purple hover:text-white hover:shadow-purple mb-20">Start recruiting</a>

                <h3 id="recruitment-with-rex" className="text-3xl font-bold pb-12 text-dark-gray">Recruitment with <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span>  is:</h3>
                <div className="grid md:grid-cols-2 gap-10">
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/efektywna.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Effective</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Projects are performed by recruiters who know the client’s sector and location, allowing them to deliver well-matched candidates</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/tansza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Cheaper</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">The cost of recruitment is 50% lower on average and the settlement model is beneficial for the client, who pays only for the employed candidates</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-16 mr-5" src={'../../images/about-icons/szybsza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Faster</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">We associate hundreds of recruiters, take action immediately and quickly provide the client with the first candidates</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/nieograniczona.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Unlimited</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">ReX Recruiters work in many different locations in several countries, meaning they have broad access to candidates</p>
                    </div>
                </div>    
            </div>
            <div className="flex justify-center items-center relative z-20 m">
                <div className="mt-20 shadow-3xl  bg-white text-center p-10 md:m-20 relative z-10">
                    <StaticImage className="w-36 mb-12" src={'../../images/logo-antal-color.svg'} alt="" />
                    <h3 className="text-purple font-bold uppercase text-lg mb-5">Powered by Antal!</h3>
                    <p className="text-gray text-lg">The Rex platform is based on the strength of the Antal brand. With more than 25 years of experience, the best experts and a guarantee of the highest quality.</p>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default About