import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HowWeWork = () => {
  return (
    <section id="how-do-we-operate" className="relative overflow-visible object-none py-24 ">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">How do we  <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">operate</span>?</h2>
            <div className="grid lg:grid-cols-3 gap-20 xl:-mx-20">
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">1</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Submission of the project <br/> by the client</h3>
                    <p className="font-light text-dark-gray text-md">We care about your time, so you do not have to register anywhere. Just send us the description of the position and answer some simple questions.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">2</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Selection of recruiters <br/> and immediate commencement of actions</h3>
                    <p className="font-light text-dark-gray text-md">You gain access to highly qualified ReX Recruiters who know the specific nature of your sector and location, thanks to which they can provide well-matched candidates. The recruiters take action right away.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">3</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Delivering candidates <br/> and project settlements</h3>
                    <p className="font-light text-dark-gray text-md">Within a few days you receive the first CVs of well-matched candidates. You pay us only if you employ a recommended candidate. Our conditions are all transparent and you do not incur any hidden costs.</p>
                </div>
            </div>
            
           
        </div>
        
    </section>
  )
}

export default HowWeWork