import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LinkedIn from "../../images/linkedin-footer.svg"
import Facebook from "../../images/facebook.svg"
import Twitter from "../../images/twitter.svg"

const Footer = () => {
  return (
    <div className="relative overflow-visible object-none py-24 ">
        <StaticImage quality={100} className="absolute inset-0" src={'../../images/tlo-szare.png'}/>
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 relative text-dark-gray ">
            <h3 className="text-center md:text-left md:flex items-center text-3xl font-bold mb-10 md:mb-0 ">Learn&nbsp;more&nbsp;<span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">about&nbsp;us</span></h3>
            <div className="text-center flex items-center justify-center mb-10 md:mb-0">
                <a href="https://www.linkedin.com/showcase/rex-recruitment-exchange/" target="_blank" className="sm-icon rounded-full bg-white w-14 h-14 flex justify-center items-center mx-1">
                    <LinkedIn className='custom-svg-gradient h-6 w-6' />
                </a>
                <a href="https://www.facebook.com/ReXRecruitmentExchange" target="_blank" className="sm-icon rounded-full bg-white w-14 h-14 flex justify-center items-center mx-1">
                    <Facebook className={'custom-svg-gradient h-6 w-6'} />
                </a>
                <a href="https://twitter.com/ExchangeRex" target="_blank" className="sm-icon rounded-full bg-white w-14 h-14 flex justify-center items-center mx-1">
                    <Twitter className={'custom-svg-gradient h-6 w-6'} />
                </a>
            </div>
            <div className="text-center md:text-right flex flex-col justify-center">
                Antal Sp. z o.o.<br/> ul. Puławska 2 | 02-566 Warszawa
            </div>
            
        </div>
        <div className="max-w-7xl mx-auto grid sm:grid-cols-2 md:grid-cols-8 border-b border-[#C2C2C2] py-20 gap-10 relative">
                <StaticImage quality={100} src={'../../images/partners/acca.png'} />
                <StaticImage quality={100} src={'../../images/partners/iaa.png'} />
                <StaticImage quality={100} src={'../../images/partners/polskie-forum-hr.png'} />
                <StaticImage quality={100} src={'../../images/partners/pracodawcy-rp.png'} />
                <StaticImage quality={100} src={'../../images/partners/pro-progressio.png'} />
                <StaticImage quality={100} src={'../../images/partners/pw-izba-gosp.png'} />
                <StaticImage quality={100} src={'../../images/partners/scandinavian-polish.png'} />
                <StaticImage quality={100} src={'../../images/partners/wsb.png'} />
            </div>
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 relative text-dark-gray pt-10 text-xs">
        <div className="text-center md:text-left mb-5 md:mb-0">   Recruitment Exchange © 2022 / All rights reserved</div>
            <div className="text-center text-md">
                <a className="mx-2" target="blank" href="/static/OWU_GTC_ReX_PL_EN.pdf">GTC/OWU</a>
                <a className="mx-2" target="blank" href="/static/RODO_GDPR_ReX_PL_EN.pdf">RODO/GDPR</a>
            </div>
        <div className="text-center md:text-right">Project: <span className="font-extrabold">Agencja Grow</span></div>
        </div>
    </div>
  )};

  export default Footer